import React from 'react'
import './EditInvite.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import {Uploader, Icon, Dropdown, Input, Button} from 'rsuite'
import Modal from "react-responsive-modal"

import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import ParintiComponent from '../AddInvite/ParintiComponent'
import NasiComponent from '../AddInvite/NasiComponent'
import LoadingModal from '../LoadingModal/LoadingModal'

import {CirclePicker} from 'react-color'
import CustomInput from '../../components/custom/CustomInput'
import {
    BackAndConfirmButtons,
    ConfirmButton,
    getUrlFromImage,
    getBase64FromImage,
    PlayVideo,
    textarea_max_length,
    EditPicture2,
    resizeBlob
} from '../../components/Helper'
import CustomQuestions from '../CustomQuestions/CustomQuestions'
import Loader from "../../components/Loader";
import InviteEditComp from "../Invite/InviteEditComp";
import {inviteTemplates} from "../../../components/inviteTemplates/inviteTemplates";
import {
    getAudioLink,
    getAvailableInviteLanguages,
    getDefaultWeddingSendInviteMessage, getLanguageFlag,
    getLanguageName,
    getWeddingParticipantsPackageNumber
} from "../../../functions";
import SendInviteModal from "../../../components/SendInviteModal/SendInviteModal";
import {
    getDefaultTemplateValues,
    getTemplateConfigValues, resetTemplateIntroScreenStyles,
    resetTemplateStyles
} from "../../../components/InviteTemplatesFunctions";
import BackgroundStyleComp
    from '../../../components/inviteTemplates/components/CustomTemplated/BackgroundStyleComp/BackgroundStyleComp'
import {injectIntl} from 'react-intl';
import moment from "moment-timezone";
import IntroAnimationConfigComp
    from "../../../components/inviteTemplates/introAnimations/IntroAnimationConfigComp/IntroAnimationConfigComp";
import AddAudio from "./components/AddAudio";
import IntroScreenEditComp
    from "../../../components/inviteTemplates/IntroScreen/IntroScreenEditComp";
import {corporateWeddingEvents} from "../../../const";


class EditInvite extends React.Component {

    constructor(props) {
        super(props)
        this.scrollContainer = React.createRef()
    }

    state = {
        inviteType: "",
        locations: [],

        inviteId: -1,
        mamaMireasa: "",
        tataMireasa: "",
        numeParintiMireasa: "",
        numeParintiMire: "",
        mamaMire: "",
        tataMire: "",

        parinti: [],
        numeNas: "",
        numeNasa: "",
        numeFamilie: "",

        timerStarted: false,
        timer: 0,
        showSendModal: false,
        showChangeModalToCustom: false,
        showChangeModalToCustomNew: false,
        showChangeModalToPersonal: false,
        showChangeModalToVideo: false,
        showChangeModalToCustomTemplated: false,
        showDownloadImageModal: false,
        inviteDownloadSize: 2,
        showChangeForm: false,
        newTemplateId: 0,

        image1Url: false,
        image2Url: false,
        image3Url: false,
        coverImageUrl: false,

        newImage1: null,
        newImage2: null,
        newImage3: null,
        newCoverImage: null,


        nasi: [],

        city: "",
        description: "",
        fontSize: "20px",
        fontColor: "#000",
        fontFamily: "Open Sans",
        fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],
        fotoID: "",
        linkVideo: "",
        poza_invite: null,
        poza_invite_new: null,

        isChangingColor: false,

        isUsingAudio: false,
        serverAudioUrl: null,
        audioType: null,
        deleteServerAudio: false,
        audioBlobData: null,
        songSelected: null,

        loading: false,
        loadingData: true,
        savingData: false,
        downloadInviteLoading: false,

        backgroundImageLink: null,
        deleteBackgroundImageLink: false,
        initialWeddingData: {},
        eventType: "",
        eventId: "",
        editSendInviteMessage: "",
        sendInviteMessage: "",

        base64ImageLink: "",

        eventImage: null,
        eventImageLink: null,
        language: 'ro',
        selectedLanguage: 'ro',

        showModalChangeType: false,

        questions: [],
        isUsingNotifications: false,
        isUsingCustomQuestions: false,
        isUsingOGImage: false,
        questionTypes: [{name: "Raspuns liber", type: 0}, {name: "Grila", type: 1}],
        canModifyQuestions: true,
        isPublished: true,
        tutorialModalPhoto: false,
        tutorialModalVideo: false,
        tutorialModalCustom: false,
        tutorialModalTemplate: false,
        tutorialModalPreview: false,
        tutorialModalSendInvite: false,
        tutorialModalSendInviteNotice: false,
        tutorialModalCustomTemplated: false,

        hideDontKnowButton: false,
        hideChildrenQuestion: false,
        hideConfirmForm: false,
        hideEmailInput: false,

        showLanguageModal: false,

        showCannotEditModal: false,

        useNewInvite: true,
        templateDetails: {},

        customAudioLink: '',
        customAudioSecond: '',

        activeTab: 'invitation',


        sortTemplates: 'top',
        afterInviteResponseModalMode: 0,

        templateSelected: false,
        finalLoading: false,
        templateConfigValues: null
    }

    async componentDidMount() {
        this.getInviteData()
    }

    async getInviteData() {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let weddingData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
        let current_invite = weddingData.data.invite

        let audioLink = await getAudioLink(weddingData.data.invite);

        if (audioLink && audioLink !== '') {
            this.setState({
                serverAudioUrl: audioLink,
                isUsingAudio: true
            })
        }
        //Creating a new additional invite
        if (this.props.match.params.new && this.props.match.params.new === 'new') {
            current_invite = null
        }
        //Edit an additional invite
        if (this.props.match.params.new && this.props.match.params.new !== 'new') {
            current_invite = weddingData.data.additional_invites.find((item) => item.id === parseInt(this.props.match.params.new + ''))
        }

        if (current_invite !== null) {
            let {
                nasi,
                description,
                parinti,
                linkVideo
            } = current_invite

            let allowAudioRequest = true
            if (current_invite.audio_request_sent_date) {
                if (moment(current_invite.audio_request_sent_date) > moment(new Date()).subtract(48, 'hours')) {
                    allowAudioRequest = false
                } else {
                    allowAudioRequest = true
                }
            }

            let dateEventEndOfDay = moment(weddingData.data.dataEvent).endOf('day').toDate()

            this.setState({
                inviteType: current_invite.inviteType,
                inviteId: current_invite.id,
                current_invite: current_invite,
                allowAudioRequest: allowAudioRequest,
                nasi,
                description,
                mamaMireasa: parinti[2].mamaMireasa,
                tataMireasa: parinti[3].tataMireasa,
                mamaMire: parinti[0].mamaMire,
                tataMire: parinti[1].tataMire,
                numeParintiMire: parinti[4].numeParintiMire,
                numeParintiMireasa: parinti[5].numeParintiMireasa,
                linkVideo,
                audioType: current_invite.audioType ? (current_invite.audioType === "useAudio" ? 'link' : current_invite.audioType) : 'song',
                image1Url: current_invite.image1 !== null && current_invite.image1 !== undefined ? current_invite.image1.url : null,
                songSelected: current_invite.song,
                image2Url: current_invite.image2 !== null && current_invite.image2 !== undefined ? current_invite.image2.url : null,
                image3Url: current_invite.image3 !== null && current_invite.image3 !== undefined ? current_invite.image3.url : null,
                coverImageUrl: current_invite.cover_image !== null && current_invite.cover_image !== undefined ? current_invite.cover_image.url : null,
                isUsingAudio: current_invite.audioType !== "none",
                backgroundImageLink: current_invite.image !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(current_invite.image)}` : null,
                fontColor: current_invite.fontColor,
                fontSize: current_invite.fontSize,
                fontFamily: current_invite.fontFamily,
                isPublished: current_invite.isPublished ? true : false,
                current_event: weddingData.data,
                templateDetails: current_invite.templateDetails,
                useNewInvite: current_invite.templateDetails && current_invite.templateDetails.id ? true : false,
                sendInviteMessage: current_invite.sendInviteMessage && current_invite.sendInviteMessage.length > 0 ? current_invite.sendInviteMessage : getDefaultWeddingSendInviteMessage(weddingData.data),
                isUsingOGImage: weddingData.data.invite ? weddingData.data.invite.isUsingOGImage : current_invite.isUsingOGImage,
                language: current_invite.language && current_invite.language.length > 0 ? current_invite.language : 'ro',
                selectedLanguage: current_invite.language && current_invite.language.length > 0 ? current_invite.language : 'ro',
                hideChildrenQuestion: current_invite.hideChildrenQuestion === true,
                hideDontKnowButton: current_invite.hideDontKnowButton === true,
                hideConfirmForm: current_invite.hideConfirmForm === true,
                hideEmailInput: current_invite.hideEmailInput === true,
                showCannotEditModal: moment().diff(moment(dateEventEndOfDay), 'days') >= 2,
                templateConfigValues: current_invite.templateDetails && current_invite.templateDetails.id ? getTemplateConfigValues(current_invite.templateDetails.id) : {}
            })


        } else {

            // We need to create an invite

            this.setState({
                sendInviteMessage: getDefaultWeddingSendInviteMessage(weddingData.data)
            })


        }

        if (!weddingData.data.invite) {
            weddingData.data.invite = {language: 'ro'}
        }


        //Update current_event lang
        let newCurrentEvent = {...weddingData.data}
        if (newCurrentEvent['invite']) {
            newCurrentEvent['invite']['language'] = this.state.selectedLanguage;
        } else {
            newCurrentEvent['invite'] = {}
            newCurrentEvent['invite']['language'] = this.state.selectedLanguage;
        }

        this.setState({
            initialWeddingData: weddingData.data,
            locations: weddingData.data.locatii ? weddingData.data.locatii : [],
            current_event: weddingData.data,
            eventType: weddingData.data.eventType,
            eventId: weddingData.data.id,
            questions: weddingData.data.questions !== null ? weddingData.data.questions : [],
            isUsingNotifications: weddingData.data.notifications,
            afterInviteResponseModalMode: weddingData.data.others && weddingData.data.others.after_invite_response_modal_mode ? weddingData.data.others.after_invite_response_modal_mode : 0,
            isUsingCustomQuestions: weddingData.data.useCustomQuestions,
            eventImageLink: weddingData.data.ogImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(weddingData.data.ogImage)}` : null,
        })

        //check if invitations have been sent (can't change settings here in this case)
        await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
            .then(response => {
                if (response.data.responses.length > 0) {
                    //Exclude deleted responses
                    let responses = response.data.responses.filter((response) => response.deleted !== true)

                    if (responses && responses.length > 0) {
                        this.setState({
                            canModifyQuestions: false
                        })
                    }
                }
            })

        this.setState({
            loadingData: false
        })
    }

    removeNasi = (id) => {
        let current = [...this.state.nasi];

        for (var i = 0; i < current.length; i++) {
            if (current[i].id === id) {
                current.splice(i, 1);
            }
        }

        this.setState({
            nasi: current
        })
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    addNasi = () => {
        let {numeNasa, numeNas, numeFamilie} = this.state;
        let current = this.state.nasi;

        current.push({
            id: this.makeid(3),
            numeNasa,
            numeNas,
            numeFamilie
        })

        this.setState({
            numeNas: "",
            numeNasa: "",
            numeFamilie: "",
            nasi: current
        })
    }

    handleChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    changeUseQuestionsState = async (state) => {


        if (this.state.canModifyQuestions === false) {
            toast.error("Aveti raspunsuri, modificarile nu mai sunt permise")
            return
        }

        this.setState({
            isUsingCustomQuestions: state
        })
    }

    changeQuestions = (newQuestions) => {
        this.setState({
            questions: newQuestions
        })
    }

    changeInviteDescription = (value) => {
        if (value.length > textarea_max_length - 1) {
            this.setState({
                description: value.substring(0, textarea_max_length)
            })
            return
        }

        this.setState({
            description: value
        })
    }

    saveNotificationStatus = async () => {
        let obj = {
            notifications: this.state.isUsingNotifications,
            useCustomQuestions: this.state.isUsingCustomQuestions,
            others: {
                after_invite_response_modal_mode: this.state.afterInviteResponseModalMode
            }
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
            .then(_ => {
            })
            .catch(err => {
                console.log(err);
            })
    }

    saveCustomQuestions = () => {

        if (!this.state.canModifyQuestions && this.state.language === 'ro') {
            return
        }

        let obj = {
            questions: this.state.questions,
            useCustomQuestions: this.state.isUsingCustomQuestions
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
            .then(_ => {
            })
            .catch(err => {
                console.log(err);
            })
    }

    sendCustomAudio = () => {

        let obj = {
            link: this.state.customAudioLink,
            second: this.state.customAudioSecond,
            invite_id: this.state.inviteId
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/sendCustomAudio/' + this.state.eventId, obj, auth)
            .then(_ => {
            })
            .catch(err => {
                console.log(err);
            })
    }

    openSendModal = async () => {
        this.setState({
            tutorialModalSendInvite: false,
            tutorialModalSendInviteNotice: false
        })

        this.setState({
            showSendModal: true
        })
    }

    chooseModal = () => {
        if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteSend)) {
            this.setState({
                tutorialModalSendInvite: true
            })
        } else {
            this.openSendModal()
        }
    }

    saveInviteData = async (redirectToPreview = false, openSendInvite = false, redirectToPackages = false) => {

        let obj = {
            inviteType: this.state.inviteType,
            parinti: [{mamaMire: this.state.mamaMire}, {tataMire: this.state.tataMire}, {mamaMireasa: this.state.mamaMireasa}, {tataMireasa: this.state.tataMireasa}, {numeParintiMire: this.state.numeParintiMire}, {numeParintiMireasa: this.state.numeParintiMireasa}],
            nasi: this.state.nasi,
            description: this.state.description,
            locatii: this.state.locations,
            linkVideo: this.state.linkVideo,
            fontColor: this.state.fontColor,
            fontSize: this.state.fontSize,
            fontFamily: this.state.fontFamily,
            imageLink: this.state.base64ImageLink,
            templateDetails: this.state.templateDetails,
            sendInviteMessage: this.state.sendInviteMessage,
            isUsingOGImage: this.state.isUsingOGImage,
            language: this.state.language,
            hideChildrenQuestion: this.state.hideChildrenQuestion,
            hideDontKnowButton: this.state.hideDontKnowButton,
            hideConfirmForm: this.state.hideConfirmForm,
            hideEmailInput: this.state.hideEmailInput

        }

        if (this.state.newImage1 !== null) {
            let image1Link = await getBase64FromImage(this.state.newImage1)
            obj["image1Link"] = image1Link
        }

        if (this.state.newImage2 !== null) {
            let image2Link = await getBase64FromImage(this.state.newImage2)
            obj["image2Link"] = image2Link
        }
        if (this.state.newImage3 !== null) {
            let image3Link = await getBase64FromImage(this.state.newImage3)
            obj["image3Link"] = image3Link
        }
        if (this.state.newCoverImage !== null) {
            let coverImageLink = await getBase64FromImage(this.state.newCoverImage)
            obj["coverImageLink"] = coverImageLink
        }


        if (this.state.inviteId !== -1) {

            let formData = new FormData()

            if (this.state.isUsingAudio && this.state.audioType === 'song' && this.state.songSelected !== null) {
                obj['song'] = this.state.songSelected

                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }

            if (this.state.isUsingAudio && this.state.audioType === 'message' && this.state.audioBlobData !== null) {
                formData.append("files.audio", this.state.audioBlobData)
                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }
            if (this.state.isUsingAudio && this.state.audioType === 'link' && this.state.customAudioLink.length > 0) {
                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }
            if (!this.state.isUsingAudio) {
                obj['audioType'] = "none";
            }
            formData.append("data", JSON.stringify(obj))

            if (this.state.poza_invite_new !== null) {
                formData.append("files.image", this.state.poza_invite_new)
            } else {
                if (this.state.deleteBackgroundImageLink) {
                    obj["image"] = null
                }
            }

            if (this.state.newImage1 !== null) {
                formData.append("files.image1", this.state.newImage1)
            }

            if (this.state.newImage2 !== null) {
                formData.append("files.image2", this.state.newImage2)
            }

            if (this.state.newImage3 !== null) {
                formData.append("files.image3", this.state.newImage3)
            }

            if (this.state.newCoverImage !== null) {
                formData.append("files.cover_image", this.state.newCoverImage)
            }


            let auth = {
                headers: {
                    "Content-Type": `multipart/form-data;`,
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            await axios.put(process.env.REACT_APP_DOMAIN + 'invites/' + this.state.inviteId, formData, auth)//formData, auth)
                .then(_ => {
                    toast.success("Modificările au fost salvate!")

                    if (openSendInvite) {
                        if (!this.state.locations || this.state.locations.length === 0) {
                            this.setState({
                                tutorialModalSendInviteNotice: true
                            })
                        } else {
                            this.chooseModal()
                        }
                    } else {
                        if (redirectToPackages) {
                            this.props.history.push('/organizer/package/' + this.props.match.params.id + '?noTutorial=true')
                            return
                        }

                        if (redirectToPreview) {
                            if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.invitePreview)) {
                                this.setState({
                                    tutorialModalPreview: true
                                })
                            } else {
                                if (!this.props.match.params.new) {
                                    this.props.history.push('/organizer/invitationPreview/' + this.props.match.params.id)
                                } else {
                                    this.props.history.push('/organizer/invitationPreview/' + this.props.match.params.id + '/' + this.props.match.params.new)
                                }
                            }
                        } else {
                            this.props.history.push('/organizer/plan/' + this.props.match.params.id)
                        }
                    }
                })
                .catch(err => {
                    toast.error("Eroare")
                    this.setState({
                        finalLoading: false
                    })
                })

        } else {

            if (!this.props.match.params.new) {
                obj.event = this.state.initialWeddingData.id
            } else {
                obj.wedding = this.state.initialWeddingData.id
            }
            obj.user = localStorage.getItem('id')


            let formData = new FormData()
            if (this.state.isUsingAudio && this.state.audioType === 'song' && this.state.songSelected !== null) {
                obj['song'] = this.state.songSelected

                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }

            if (this.state.isUsingAudio && this.state.audioType === 'message' && this.state.audioBlobData !== null) {
                formData.append("files.audio", this.state.audioBlobData)
                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }
            if (this.state.isUsingAudio && this.state.audioType === 'link' && this.state.customAudioLink.length > 0) {
                obj['audioType'] = this.state.audioType ? this.state.audioType : "none";
            }
            if (!this.state.isUsingAudio) {
                obj['audioType'] = "none";
            }
            formData.append("data", JSON.stringify(obj))

            if (this.state.poza_invite_new !== null) {
                formData.append("files.image", this.state.poza_invite_new)
            }

            if (this.state.newImage1 !== null) {
                formData.append("files.image1", this.state.newImage1)
            }

            if (this.state.newImage2 !== null) {
                formData.append("files.image2", this.state.newImage2)
            }

            if (this.state.newImage3 !== null) {
                formData.append("files.image3", this.state.newImage3)
            }

            if (this.state.newCoverImage !== null) {
                formData.append("files.cover_image", this.state.newCoverImage)
            }

            let auth = {
                headers: {
                    "Content-Type": `multipart/form-data;`,
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            await axios.post(process.env.REACT_APP_DOMAIN + 'invites', formData, auth)//formData, auth)
                .then(async response => {
                    toast.success("Invitatie creata cu succes!")

                    let obj2 = {
                        invitatieInfo: true
                    }

                    let auth2 = {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                        }
                    }

                    await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.initialWeddingData.id, obj2, auth2)

                    if (openSendInvite) {
                        if (!this.state.locations || this.state.locations.length === 0) {
                            this.setState({
                                tutorialModalSendInviteNotice: true
                            })
                        } else {
                            this.chooseModal()
                        }
                    } else {

                        if (redirectToPackages) {
                            this.props.history.push('/organizer/package/' + this.props.match.params.id + '?noTutorial=true')
                            return
                        }

                        if (redirectToPreview) {
                            if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.invitePreview)) {
                                this.setState({
                                    tutorialModalPreview: true
                                })
                            } else {
                                if (!this.props.match.params.new) {
                                    this.props.history.push('/organizer/invitationPreview/' + this.props.match.params.id)
                                } else {
                                    this.props.history.push('/organizer/invitationPreview/' + this.props.match.params.id + '/' + this.props.match.params.new)
                                }
                            }
                        } else {
                            this.props.history.push('/organizer/plan/' + this.props.match.params.id)
                        }
                    }
                })
                .catch(err => {
                    toast.error("Eroare")
                    this.setState({
                        finalLoading: false
                    })
                })

        }

        this.setState({
            finalLoading: false
        })
    }


    setInviteSentDate = async () => {

        //disabled
        return

        // eslint-disable-next-line
        let obj = {
            id_eveniment: this.props.match.params.id
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/setInviteSentDate',
            obj)
    }

    resetInviteSentDate = async () => {
        return

        // eslint-disable-next-line
        let obj = {
            id_eveniment: this.props.match.params.id
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/resetInviteSentDate',
            obj)

    }

    createInvite = async (redirectToPreview = false, openSendInvite = false, redirectToPackages = false) => {

        if (this.state.finalLoading || this.state.savingData) {
            return
        }

        this.setState({
            savingData: true,
            finalLoading: true
        })

        if (this.state.customAudioLink.length > 0) {
            await this.sendCustomAudio()
        }

        if (this.state.isUsingCustomQuestions) {
            if (!this.state.canModifyQuestions && this.state.language === 'ro') {
                //toast.info("S-au primit raspunsuri la invitatie, modificarile la intrebari nu vor fi salvate")
            } else {
                let questions = this.state.questions
                //check questions for validity
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].title === "") {
                        toast.error(`Va rog completati toate titlurile. Titlu intrebare ${i + 1}`)
                        return
                    }

                    if (questions[i].type === 1) {
                        if (questions[i].options.length === 0) {
                            toast.error(`Ati ales intrebare grila dar nu ati oferit optiuni. Intrebare ${i + 1}`)
                            return
                        }

                        for (let j = 0; j < questions[i].options.length; j++) {
                            if (questions[i].options[j] === "") {
                                toast.error(`Va rog completati toate optiunile unde ati ales grila. Intrebare ${i + 1}, grila ${j + 1}`)
                                return
                            }
                        }
                    }
                }

                this.saveCustomQuestions()
            }
        }


        await this.saveNotificationStatus()

        await this.resetInviteSentDate()
        await this.saveInviteData(redirectToPreview, openSendInvite, redirectToPackages)

        this.setState({
            savingData: false
        })
    }

    saveWeddingData = async (weddingData, locations) => {
        let obj = {
            id_eveniment: weddingData.id_eveniment,
            locations: locations,
            prenumeMireasa: weddingData.prenumeMireasa,
            prenumeMire: weddingData.prenumeMire,
            dataEvent: weddingData.dataEvent,
            eventDescription: weddingData.eventDescription,
            isPublished: true
        }

        let newWeddingData = {...this.state.initialWeddingData}
        newWeddingData.prenumeMire = weddingData.prenumeMire
        newWeddingData.prenumeMireasa = weddingData.prenumeMireasa
        newWeddingData.dataEvent = weddingData.dataEvent
        newWeddingData.locations = weddingData.locations
        newWeddingData.prenumeTata = weddingData.prenumeTata
        newWeddingData.prenumeMama = weddingData.prenumeMama

        this.setState({
            initialWeddingData: newWeddingData,
            current_event: newWeddingData
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/edit/' + weddingData.id, obj, auth)//formData, auth)
            .then(response => {
            })
            .catch(err => {
            })
    }

    handlePoza = async (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                poza_invite: null
            })
            return
        }

        if (fileList.length > 1) {
            fileList = [fileList[0]]
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        this.setState({
            poza_invite: fileList[0].blobFile
        })
    }

    saveCroppedImage = async (croppedImg) => {

        let data = await getBase64FromImage(croppedImg)

        this.setState({
            croppedImage: croppedImg,
            base64ImageLink: data,
            poza_invite: null,
            poza_invite_new: croppedImg,
            backgroundImageLink: data
        })
    }

    openModalChangeType = () => {
        this.setState({
            showModalChangeType: true
        })
    }

    closeModalChangeType = () => {
        this.setState({
            showModalChangeType: false
        })
    }

    updateTutorial = async (tutorialInviteType) => {

        this.setState({
            tutorialModalPhoto: false,
            tutorialModalVideo: false,
            tutorialModalCustom: false,
            tutorialModalTemplate: false,
            tutorialModalCustomTemplated: false
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {}
        obj[tutorialInviteType] = true

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
            obj,
            auth)

    }

    changeType = () => {
        this.setState({
            inviteType: "",

            mamaMireasa: "",
            tataMireasa: "",
            numeParintiMireasa: "",
            numeParintiMire: "",
            mamaMire: "",
            tataMire: "",

            numeNas: "",
            numeNasa: "",
            numeFamilie: "",

            nasi: [],

            description: "",
            fontSize: "20px",
            fontColor: "#000",
            fontFamily: "Open Sans",
            fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],
            linkVideo: "",
            poza_invite: null,
            isUsingAudio: false,
            serverAudioUrl: null,
            audioBlobData: null,

            questions: this.state.initialWeddingData.questions,
            isUsingNotifications: this.state.initialWeddingData.notifications,
            isUsingCustomQuestions: this.state.initialWeddingData.useCustomQuestions,
            isUsingOGImage: false,

            showModalChangeType: false
        })
    }


    changeTemplateValue = (index, value) => {
        let newTemplateDetails = this.state.templateDetails ? {...this.state.templateDetails} : {}
        newTemplateDetails[index] = value

        if (index === 'id') {
            newTemplateDetails = resetTemplateStyles(newTemplateDetails)
            newTemplateDetails = resetTemplateIntroScreenStyles(newTemplateDetails)
            newTemplateDetails.introAnimationId = 0
            newTemplateDetails.introAnimationConfiguration = []
            newTemplateDetails.introScreenId = null
            newTemplateDetails.introScreenConfiguration = []
            this.setState({
                templateConfigValues: getTemplateConfigValues(value)
            })

        }

        if (!newTemplateDetails.section1) {
            newTemplateDetails = {...newTemplateDetails, ...getDefaultTemplateValues({data: this.state.initialWeddingData}, value)}
        }


        this.setState({
            activeTab: 'invitation',
            templateDetails: newTemplateDetails
        })
    }

    scrollContainerTop = () => {
        this.scrollContainer.current.scroll({
            top: 0,
            //behavior: 'smooth'
        })
    }

    handleEventPoza = async (fileList) => {

        if (fileList.length === 0) {
            this.setState({
                eventImage: null
            })
            return
        }

        if (fileList.length > 1) {
            fileList = [fileList[fileList.length - 1]]
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        let blob = await resizeBlob(fileList[0].blobFile)

        this.setState({
            eventImage: [blob]
        })
    }

    saveCroppedEventImage = async (croppedImg) => {
        let img = {}
        img.blobFile = croppedImg
        let data = await getBase64FromImage(img)

        this.setState({
            eventCroppedImage: croppedImg,
            eventImageLink: data,
            eventImage: null
        })

        let obj = {
            imageLink: data,
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/editImage/' + this.state.eventId, obj, auth)//formData, auth)
            .then(response => {
                if (response.data.status === 1) {
                    toast.success("Imaginea a fost salvată!")
                } else {
                    toast.error("A apărut o eroare!")
                }
            })
            .catch(err => {
                toast.error("A apărut o eroare!")
            })
    }

    changeInviteTypeToCustomTemplated = () => {
        this.changeTemplateValue('id',
            (this.state.initialWeddingData.eventType === 'Nunta' || this.state.initialWeddingData.eventType === 'Cununie') ?
                1000
                :
                (this.state.initialWeddingData.eventType === 'Botez' || this.state.initialWeddingData.eventType === 'Mot' || this.state.initialWeddingData.eventType === 'Turta') ?
                    1001
                    :
                    this.state.initialWeddingData.eventType === 'Petrecere' ?
                        1002
                        : corporateWeddingEvents.includes(this.state.initialWeddingData.eventType) ?
                        1003
                        :1001
        )
    }

    changeInviteLanguage = () => {

        let newTemplateDetails = getDefaultTemplateValues({data: this.state.current_event}, this.state.templateDetails.id, this.state.selectedLanguage)
        newTemplateDetails.id = this.state.templateDetails.id

        //Update current_event lang
        let newCurrentEvent = {...this.state.current_event}
        newCurrentEvent['invite']['language'] = this.state.selectedLanguage;

        this.setState({
            current_event: newCurrentEvent,
            language: this.state.selectedLanguage,
            templateDetails: newTemplateDetails,
            showLanguageModal: false
        })
    }

    sortTemplates = (templates) => {
        if (this.state.sortTemplates === 'top') {
            templates.sort(function (a, b) {
                return (a.usage ? a.usage : 0) - (b.usage ? b.usage : 0)
            })
            return templates
        } else {
            return templates
        }
    }

    goBack = () => {
        this.props.history.push("/organizer/plan/" + this.props.match.params.id)
    }

    render() {
        if (this.state.loadingData) {
            return <div className="AddInvite-container">
                <HeaderWhite goBack={this.goBack}/>

                <Loader/>

                <NavbarColor history={this.props.history}/>
            </div>
        }

        let customInputStyle = {
            margin: "10px 0px",
            backgroundColor: "#E5F7FE",
            borderRadius: "10px"
        }

        let body = <div></div>

        if (this.state.inviteType === "Personal") {
            body = <div>
                <div className="Upload-zone">
                    {this.state.backgroundImageLink !== null
                        ? <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <p>Invitatie personala</p>
                            <img style={{padding: "5px", width: '100%', alignSelf: "center"}}
                                 src={this.state.backgroundImageLink} alt=""/>
                        </div>
                        : <p>Nicio imagine adaugata</p>
                    }

                    <h3> Schimba invitatia personala </h3>
                    <Uploader onChange={this.handlePoza} accept=".jpg" style={{borderRadius: "5px"}}
                              autoUpload={false}
                              fileListVisible={false}
                              multiple={false} disabled={this.state.poza_invite !== null}>
                        <div style={{color: "#221d5d", width: "100%", height: "100%"}}
                             id="text">Click aici pentru a
                            uploada fotografie
                        </div>
                    </Uploader>
                </div>

                {this.state.poza_invite !== null &&
                    <EditPicture2 uploadedImage={this.state.poza_invite}
                                  width={291} //1164
                                  height={552} //2210
                                  border={0}
                                  borderRadius={0}
                                  saveCroppedImage={this.saveCroppedImage}/>}


                <div className="Section">
                    <div className="Title">Personalizare text trimitere invitație
                    </div>

                    {
                        this.state.editSendInviteMessage ?
                            <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0,255)})}
                                    />
                                <div>{this.state.sendInviteMessage.length}/255</div>
                            </div>
                            : null
                    }


                    <div className="Options-Row">
                        {
                            this.state.editSendInviteMessage ?
                                <div
                                    className={"active-option"}
                                    style={{width: 'unset'}}
                                    onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                </div>
                                :
                                <div
                                    className={"active-option"}
                                    style={{width: 'unset'}}
                                    onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                </div>
                        }
                    </div>
                </div>

                {!window.flutter_inappwebview ? <div className="Section">
                        <div className="Title">Doriți să adăugați o melodie sau să înregistrați un mesaj
                            audio pentru invitație?
                        </div>

                        <div className="Options-Row">
                            <div
                                className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                onClick={_ => {
                                    this.setState({isUsingAudio: true})
                                }} style={{marginRight: "30px"}}>Da
                            </div>
                            <div
                                className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                onClick={_ => this.setState({isUsingAudio: false})}>Nu
                            </div>
                        </div>
                    </div>
                    : <div className="Section">
                        <div className="Title">Pentru a adauga un mesaj audio va rugam sa folositi
                            un laptop/pc
                        </div>
                    </div>}

                {this.state.isUsingAudio &&
                    <AddAudio
                        initialWeddingData={this.state.initialWeddingData}
                        audioType={this.state.audioType} serverAudioUrl={this.state.serverAudioUrl}
                        deleteServerAudio={this.state.deleteServerAudio}
                        allowAudioRequest={this.state.allowAudioRequest}
                        customAudioLink={this.state.customAudioLink}
                        customAudioSecond={this.state.customAudioSecond}
                        songSelected={this.state.songSelected}
                        setAudioType={(value) => this.setState({audioType: value})}
                        setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                        setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                        setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                        setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                        setSongSelected={(value) => this.setState({songSelected: value})}
                    />}
            </div>
        }

        if (this.state.inviteType === "Video") {
            let linkParts = this.state.linkVideo.split("watch?v=")
            let link = ""
            if (linkParts.length === 1) {
                link = linkParts[0]
            } else {
                link = linkParts[0] + "embed/" + linkParts[1]
            }

            let videoId = ""

            if (this.state.linkVideo !== "") {
                try {
                    videoId = this.state.linkVideo.trim().split("watch?v=")[1].split("&")[0]
                } catch (e) {

                }
            }

            body = <div>
                {/* <iframe src={link.split("&")[0]}
					frameBorder='0'
					allow='autoplay; encrypted-media'
					allowFullScreen={true}
					title='video'
				/> */}
                <PlayVideo videoId={videoId} link={link.split("&")[0]}/>

                <div>Daca nu merge video-ul, va rog acordati permisiunile necesare din youtube.
                </div>

                <CustomInput value={this.state.linkVideo}
                             onChange={value => this.setState({linkVideo: value})}
                             placeholder={"Link video Invitatie"}
                             style={customInputStyle} icon={<Icon icon="text-height"/>}/>

                <a style={{display: "table-cell"}} href={this.state.linkVideo} target="_blank"
                   rel="noopener noreferrer">Deschide link</a>
            </div>
        }

        if (this.state.inviteType === "Custom") {

            if (this.state.useNewInvite) {
                //Templated custom invite
                body = <>

                    {this.state.templateConfigValues && (this.state.templateConfigValues.introAnimations || this.state.templateConfigValues.introScreen) &&
                        <div className={"eic-tabs"}>
                            <div
                                onClick={() => this.setState({activeTab: 'invitation'})}
                                className={"eic-tab " + (this.state.activeTab === 'invitation' ? 'active' : '')}>
                                Invitație
                            </div>
                            {this.state.templateConfigValues.introAnimations &&
                                <div
                                    onClick={() => {
                                        this.setState({activeTab: 'introAnimation'})

                                    }}
                                    className={"eic-tab " + (this.state.activeTab === 'introAnimation' ? 'active' : '')}>
                                    Animație
                                </div>}
                            {this.state.templateConfigValues.introScreen && <div
                                onClick={() => {
                                    this.setState({activeTab: 'introScreen'})

                                }}
                                className={"eic-tab " + (this.state.activeTab === 'introScreen' ? 'active' : '')}>
                                Introducere
                            </div>}
                        </div>}

                    {this.state.activeTab === 'invitation' ?
                        <>
                            <InviteEditComp
                                eventType={this.state.eventType}
                                weddingData={this.state.initialWeddingData}
                                saveWeddingData={this.saveWeddingData}
                                templateDetails={this.state.templateDetails}
                                setTemplateDetails={(val) => this.setState({templateDetails: val})}
                                audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                image1Link={this.state.image1Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image1Url}` : ''}
                                image2Link={this.state.image2Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image2Url}` : ''}
                                image3Link={this.state.image3Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image3Url}` : ''}
                                coverImageLink={this.state.coverImageUrl ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.coverImageUrl}` : ''}
                                newImage1={this.state.newImage1}
                                newImage2={this.state.newImage2}
                                newImage3={this.state.newImage3}
                                newCoverImage={this.state.newCoverImage}
                                setNewImage1={(val) => {
                                    this.setState({newImage1: val})
                                }}
                                setNewImage2={(val) => {
                                    this.setState({newImage2: val})
                                }}
                                setNewImage3={(val) => {
                                    this.setState({newImage3: val})
                                }}
                                setNewCoverImage={(val) => {
                                    this.setState({newCoverImage: val})
                                }}
                                changeTypeToCustom={() => {
                                    this.setState({
                                        activeTab: 'invitation',
                                        templateDetails: null,
                                        useNewInvite: false
                                    })
                                }}
                                updateLocations={(val) => this.setState({locations: val})}
                            />


                            <div className="Section">
                                <div className="Title">Personalizare text trimitere invitație
                                </div>
                                {
                                    this.state.editSendInviteMessage ?
                                        <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0,255)})}
                                    />
                                            <div>{this.state.sendInviteMessage.length}/255</div>
                                        </div>
                                        : null
                                }
                                <div className="Options-Row">
                                    {
                                        this.state.editSendInviteMessage ?
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                            </div>
                                            :
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="Section">
                                <div className="Title">Adăugare imagine invitație</div>
                                <div>Această imagine va apărea în momentul în care trimiteți
                                    invitația
                                </div>

                                <div className="Options-Row">
                                    <div
                                        className={this.state.isUsingOGImage ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({isUsingOGImage: true})}
                                        style={{marginRight: "30px"}}>Da
                                    </div>
                                    <div
                                        className={!this.state.isUsingOGImage ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({isUsingOGImage: false})}>Nu
                                    </div>
                                </div>

                                {this.state.isUsingOGImage ?
                                    <>
                                        {this.state.eventImageLink !== null
                                            ? <img style={{
                                                padding: "5px",
                                                width: "290px",
                                                height: "148px",
                                                alignSelf: "center"
                                            }}
                                                   src={this.state.eventImageLink} alt=""/>
                                            : <div>Nicio imagine adaugată</div>
                                        }

                                        <div style={customInputStyle}>
                                            <Uploader onChange={this.handleEventPoza} accept=".jpg"
                                                      style={{borderRadius: "5px"}}
                                                      fileListVisible={false}
                                                      autoUpload={false} multiple={false}
                                                      disabled={this.state.eventImage !== null}>
                                                <div style={{
                                                    color: "#221d5d",
                                                    width: "100%",
                                                    height: "100%"
                                                }}
                                                     id="text">Click aici
                                                    pentru a încărca imaginea
                                                </div>
                                            </Uploader>
                                        </div>

                                        {this.state.eventImage !== null && this.state.eventImage[0] ?
                                            <EditPicture2 uploadedImage={this.state.eventImage[0]}
                                                          width={290} //1164
                                                          height={148} //2210
                                                          border={0}
                                                          borderRadius={0}
                                                          saveCroppedImage={this.saveCroppedEventImage}/>
                                            : null
                                        }
                                    </>
                                    : null}
                            </div>

                            {!window.flutter_inappwebview ? <div className="Section">
                                    <div className="Title">Doriți să adăugați o melodie sau să
                                        înregistrați un mesaj audio pentru invitație?
                                    </div>

                                    <div className="Options-Row">
                                        <div
                                            className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => {
                                                this.setState({isUsingAudio: true})
                                            }} style={{marginRight: "30px"}}>Da
                                        </div>
                                        <div
                                            className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => this.setState({isUsingAudio: false})}>Nu
                                        </div>
                                    </div>
                                </div>
                                : <div className="Section">
                                    <div className="Title">Pentru a adauga un mesaj audio va rugam
                                        sa folositi un
                                        laptop/pc
                                    </div>
                                </div>}

                            {this.state.isUsingAudio &&
                                <AddAudio
                                    initialWeddingData={this.state.initialWeddingData}
                                    audioType={this.state.audioType}
                                    serverAudioUrl={this.state.serverAudioUrl}
                                    deleteServerAudio={this.state.deleteServerAudio}
                                    allowAudioRequest={this.state.allowAudioRequest}
                                    customAudioLink={this.state.customAudioLink}
                                    customAudioSecond={this.state.customAudioSecond}
                                    songSelected={this.state.songSelected}
                                    setAudioType={(value) => this.setState({audioType: value})}
                                    setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                                    setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                                    setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                                    setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                                    setSongSelected={(value) => this.setState({songSelected: value})}
                                />}
                        </> : null}
                </>
            } else {
                // Old Custom invite
                body = <div>
                    {this.state.eventType !== "Petrecere" &&
                        <ParintiComponent eventType={this.state.eventType}
                                          handleChange={this.handleChange}
                                          tataMire={this.state.tataMire}
                                          mamaMire={this.state.mamaMire}
                                          numeParintiMire={this.state.numeParintiMire}
                                          tataMireasa={this.state.tataMireasa}
                                          mamaMireasa={this.state.mamaMireasa}
                                          numeParintiMireasa={this.state.numeParintiMireasa}
                        />}

                    {this.state.eventType !== "Petrecere" &&
                        <NasiComponent nasi={this.state.nasi} handleChange={this.handleChange}
                                       addNasi={this.addNasi}
                                       removeNasi={this.removeNasi}
                                       numeNasa={this.state.numeNasa} numeNas={this.state.numeNas}
                                       numeFamilie={this.state.numeFamilie}
                        />}

                    <CustomInput value={this.state.description}
                                 onChange={this.changeInviteDescription}
                                 componentClass={"textarea"} icon={<Icon icon="pencil"/>}
                                 placeholder={`Text Invitatie ${this.state.description.length}/${textarea_max_length}`}
                                 style={customInputStyle}/>

                    <div style={{fontSize: "18px"}}>Format text</div>
                    <div className="Invite-Design">
                        <div>
                            <p>Dimensiune</p>

                            <Input value={this.state.fontSize.split("p")[0]}
                                   onChange={value => this.setState({fontSize: value + "px"})}
                                   placeholder={"Dimensiune"}
                                   style={{
                                       backgroundColor: "#E5F7FE",
                                       borderRadius: "10px",
                                       width: "100px"
                                   }}
                                   icon={<Icon icon="text-height"/>} type={"number"}/>
                        </div>

                        <div className="color-container">
                            <p>Culoare</p>

                            <div className="color-choice">
                                <div style={{
                                    height: "35px",
                                    width: "35px",
                                    backgroundColor: this.state.fontColor,
                                    borderRadius: "50%",
                                    border: '1px solid #000'
                                }} onClick={() => this.setState({isChangingColor: true})}></div>

                                {this.state.isChangingColor &&
                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal-colors',
                                        closeButton: 'modal-close-button',
                                    }} center open={this.state.isChangingColor}
                                           onClose={() => this.setState({isChangingColor: false})}>
                                        <div className="color-options">
                                            <CirclePicker color={this.state.fontColor}
                                                          onChange={newColor => this.setState({
                                                              fontColor: newColor.hex,
                                                              isChangingColor: false
                                                          })}/>

                                            <div className="basic-color">
                                                <div className="basic-color-white"
                                                     onClick={() => this.setState({
                                                         fontColor: "#fff",
                                                         isChangingColor: false
                                                     })}></div>
                                                <div className="basic-color-black"
                                                     onClick={() => this.setState({
                                                         fontColor: "#000",
                                                         isChangingColor: false
                                                     })}></div>
                                            </div>
                                        </div>
                                    </Modal>}
                            </div>
                        </div>

                        <div style={{textAlign: "center"}}>
                            <p>Font</p>

                            <Dropdown title={this.state.fontFamily}
                                      activeKey={this.state.fontFamily}
                                      className="dropdown_scroll"
                                      style={{backgroundColor: "#E5F7FE", borderRadius: "10px"}}>
                                {this.state.fontsOptions.map((option, index) => {
                                    return <Dropdown.Item key={index} eventKey={option}
                                                          onClick={() => this.setState({fontFamily: option})}>{option}</Dropdown.Item>
                                })}
                            </Dropdown>
                        </div>
                    </div>

                    <p style={{
                        fontSize: this.state.fontSize,
                        fontFamily: this.state.fontFamily,
                        color: "" + this.state.fontColor,
                        marginBottom: "20px"
                    }}>Text final</p>

                    {this.state.backgroundImageLink !== null
                        ? <div>
                            <p>Imagine fundal</p>
                            <img style={{padding: "5px", width: '100%', alignSelf: "center"}}
                                 src={this.state.backgroundImageLink} alt=""/>

                            {this.state.poza_invite === null && <div className="Section">
                                <div className="Title">Doriti sa stergeti imaginea de fundal
                                    curenta?
                                </div>

                                <div className="Options-Row">
                                    <div
                                        className={this.state.deleteBackgroundImageLink ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({deleteBackgroundImageLink: true})}
                                        style={{marginRight: "30px"}}>Da
                                    </div>
                                    <div
                                        className={!this.state.deleteBackgroundImageLink ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({deleteBackgroundImageLink: false})}>Nu
                                    </div>
                                </div>
                            </div>}
                        </div>
                        : <p>Nicio imagine adaugata</p>
                    }

                    <div className="Upload-zone">
                        <p> {this.state.backgroundImageLink !== null ? "Schimba Fotografia de Fundal (va sterge imaginea curenta)" : "Adauga fotografie de fundal"} </p>

                        <Uploader onChange={this.handlePoza} accept=".jpg"
                                  style={{borderRadius: "5px"}}
                                  autoUpload={false} multiple={false}
                                  disabled={this.state.poza_invite !== null}
                                  fileListVisible={false}
                        >
                            <div style={{color: "#221d5d", width: "100%", height: "100%"}}
                                 id="text">Click aici pentru a
                                incarca fotografia
                            </div>
                        </Uploader>
                    </div>

                    {this.state.poza_invite !== null &&
                        <EditPicture2 uploadedImage={this.state.poza_invite}
                                      width={291} //1164
                                      height={552} //2210
                                      border={0}
                                      borderRadius={0}
                                      saveCroppedImage={this.saveCroppedImage}/>}

                    <div className="Section">
                        <div className="Title">Personalizare text trimitere invitație
                        </div>

                        {
                            this.state.editSendInviteMessage ?
                                <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0,255)})}
                                    />
                                    <div>{this.state.sendInviteMessage.length}/255</div>
                                </div>
                                : null
                        }


                        <div className="Options-Row">
                            {
                                this.state.editSendInviteMessage ?
                                    <div
                                        className={"active-option"}
                                        style={{width: 'unset'}}
                                        onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                    </div>
                                    :
                                    <div
                                        className={"active-option"}
                                        style={{width: 'unset'}}
                                        onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                    </div>
                            }
                        </div>
                    </div>

                    <div className="Section">
                        <div className="Title">Adăugare imagine invitație</div>
                        <div>Această imagine va apărea în momentul în care trimiteți invitația</div>
                        {this.state.eventImageLink !== null
                            ? <img style={{
                                padding: "5px",
                                width: "290px",
                                height: "148px",
                                alignSelf: "center"
                            }}
                                   src={this.state.eventImageLink} alt=""/>
                            : <div>Nicio imagine adaugată</div>
                        }

                        <div style={customInputStyle}>
                            <Uploader onChange={this.handleEventPoza} accept=".jpg"
                                      style={{borderRadius: "5px"}}
                                      fileListVisible={false}
                                      autoUpload={false} multiple={false}
                                      disabled={this.state.eventImage !== null}>
                                <div style={{color: "#221d5d", width: "100%", height: "100%"}}
                                     id="text">Click aici
                                    pentru a încărca imaginea
                                </div>
                            </Uploader>
                        </div>

                        {this.state.eventImage !== null && this.state.eventImage[0] ?
                            <EditPicture2 uploadedImage={this.state.eventImage[0]}
                                          width={290} //1164
                                          height={148} //2210
                                          border={0}
                                          borderRadius={0}
                                          saveCroppedImage={this.saveCroppedEventImage}/>
                            : null
                        }
                    </div>


                    {!window.flutter_inappwebview ? <div className="Section">
                            <div className="Title">Doriți să adăugați o melodie sau să înregistrați un
                                mesaj audio pentru invitație?
                            </div>

                            <div className="Options-Row">
                                <div
                                    className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                    onClick={_ => {
                                        this.setState({isUsingAudio: true})
                                    }} style={{marginRight: "30px"}}>Da
                                </div>
                                <div
                                    className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                    onClick={_ => this.setState({isUsingAudio: false})}>Nu
                                </div>
                            </div>
                        </div>
                        : <div className="Section">
                            <div className="Title">Pentru a adauga un mesaj audio va rugam sa
                                folositi un laptop/pc
                            </div>
                        </div>}

                    {this.state.isUsingAudio &&
                        <AddAudio
                            initialWeddingData={this.state.initialWeddingData}
                            audioType={this.state.audioType}
                            serverAudioUrl={this.state.serverAudioUrl}
                            deleteServerAudio={this.state.deleteServerAudio}
                            allowAudioRequest={this.state.allowAudioRequest}
                            customAudioLink={this.state.customAudioLink}
                            customAudioSecond={this.state.customAudioSecond}
                            songSelected={this.state.songSelected}
                            setAudioType={(value) => this.setState({audioType: value})}
                            setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                            setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                            setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                            setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                            setSongSelected={(value) => this.setState({songSelected: value})}
                        />}

                </div>
            }
        }

        if (this.state.inviteType === "CustomTemplated") {
            body =
                <div>
                    {this.state.templateConfigValues && (this.state.templateConfigValues.introAnimations || this.state.templateConfigValues.introScreen) &&
                        <div className={"eic-tabs"}>
                            <div
                                onClick={() => this.setState({activeTab: 'invitation'})}
                                className={"eic-tab " + (this.state.activeTab === 'invitation' ? 'active' : '')}>
                                Invitație
                            </div>
                            {this.state.templateConfigValues.introAnimations &&
                                <div
                                    onClick={() => {
                                        this.setState({activeTab: 'introAnimation'})

                                    }}
                                    className={"eic-tab " + (this.state.activeTab === 'introAnimation' ? 'active' : '')}>
                                    Animație
                                </div>}
                            {this.state.templateConfigValues.introScreen && <div
                                onClick={() => {
                                    this.setState({activeTab: 'introScreen'})

                                }}
                                className={"eic-tab " + (this.state.activeTab === 'introScreen' ? 'active' : '')}>
                                Introducere
                            </div>}
                        </div>}


                    {this.state.activeTab === 'invitation' ?
                        <>
                            <BackgroundStyleComp
                                templateDetails={this.state.templateDetails}
                                setTemplateDetails={(val) => this.setState({templateDetails: val})}
                                backgroundImageLink={this.state.backgroundImageLink}
                                deleteBackgroundImageLink={this.state.deleteBackgroundImageLink}
                                setDeleteBackgroundImageLink={(val) => this.setState({deleteBackgroundImageLink: val})}
                                handlePoza={this.handlePoza}
                                poza_invite={this.state.poza_invite}
                                saveCroppedImage={this.saveCroppedImage}
                            />

                            <InviteEditComp
                                eventType={this.state.eventType}
                                weddingData={this.state.initialWeddingData}
                                saveWeddingData={this.saveWeddingData}
                                templateDetails={this.state.templateDetails}
                                setTemplateDetails={(val) => this.setState({templateDetails: val})}
                                audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                image1Link={this.state.image1Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image1Url}` : ''}
                                image2Link={this.state.image2Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image2Url}` : ''}
                                image3Link={this.state.image3Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image3Url}` : ''}
                                coverImageLink={this.state.coverImageUrl ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.coverImageUrl}` : ''}
                                newImage1={this.state.newImage1}
                                newImage2={this.state.newImage2}
                                newImage3={this.state.newImage3}
                                newCoverImage={this.state.newCoverImage}
                                setNewImage1={(val) => {
                                    this.setState({newImage1: val})
                                }}
                                setNewImage2={(val) => {
                                    this.setState({newImage2: val})
                                }}
                                setNewImage3={(val) => {
                                    this.setState({newImage3: val})
                                }}
                                setNewCoverImage={(val) => {
                                    this.setState({newCoverImage: val})
                                }}
                                changeTypeToCustom={() => {
                                    this.setState({
                                        templateDetails: null,
                                        useNewInvite: false
                                    })
                                }}
                                updateLocations={(val) => this.setState({locations: val})}
                            />

                            <div className="Section">
                                <div className="Title">Personalizare text trimitere invitație
                                </div>
                                {
                                    this.state.editSendInviteMessage ?
                                        <div className={""}>
                                    <textarea
                                        style={{
                                            width: '80%',
                                            height: '100px',
                                            margin: 'auto',
                                            borderColor: '#ccc',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                        maxLength={255}
                                        value={this.state.sendInviteMessage}
                                        onChange={(e) => this.setState({sendInviteMessage: e.target.value.substring(0,255)})}
                                    />
                                            <div>{this.state.sendInviteMessage.length}/255</div>
                                        </div>
                                        : null
                                }
                                <div className="Options-Row">
                                    {
                                        this.state.editSendInviteMessage ?
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: false})}>Salvează
                                            </div>
                                            :
                                            <div
                                                className={"active-option"}
                                                style={{width: 'unset'}}
                                                onClick={_ => this.setState({editSendInviteMessage: true})}>Editează
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="Section">
                                <div className="Title">Adăugare imagine invitație</div>
                                <div>Această imagine va apărea în momentul în care trimiteți
                                    invitația
                                </div>

                                <div className="Options-Row">
                                    <div
                                        className={this.state.isUsingOGImage ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({isUsingOGImage: true})}
                                        style={{marginRight: "30px"}}>Da
                                    </div>
                                    <div
                                        className={!this.state.isUsingOGImage ? "active-option" : "inactive-option"}
                                        onClick={_ => this.setState({isUsingOGImage: false})}>Nu
                                    </div>
                                </div>

                                {this.state.isUsingOGImage ?
                                    <>
                                        {this.state.eventImageLink !== null
                                            ? <img style={{
                                                padding: "5px",
                                                width: "290px",
                                                height: "148px",
                                                alignSelf: "center"
                                            }}
                                                   src={this.state.eventImageLink} alt=""/>
                                            : <div>Nicio imagine adaugată</div>
                                        }

                                        <div style={customInputStyle}>
                                            <Uploader onChange={this.handleEventPoza} accept=".jpg"
                                                      style={{borderRadius: "5px"}}
                                                      fileListVisible={false}
                                                      autoUpload={false} multiple={false}
                                                      disabled={this.state.eventImage !== null}>
                                                <div style={{
                                                    color: "#221d5d",
                                                    width: "100%",
                                                    height: "100%"
                                                }}
                                                     id="text">Click aici
                                                    pentru a încărca imaginea
                                                </div>
                                            </Uploader>
                                        </div>

                                        {this.state.eventImage !== null && this.state.eventImage[0] ?
                                            <EditPicture2 uploadedImage={this.state.eventImage[0]}
                                                          width={290} //1164
                                                          height={148} //2210
                                                          border={0}
                                                          borderRadius={0}
                                                          saveCroppedImage={this.saveCroppedEventImage}/>
                                            : null
                                        }
                                    </>
                                    : null}
                            </div>

                            {!window.flutter_inappwebview ? <div className="Section">
                                    <div className="Title">Doriți să adăugați o melodie sau să
                                        înregistrați un mesaj audio pentru invitație?
                                    </div>

                                    <div className="Options-Row">
                                        <div
                                            className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => {
                                                this.setState({isUsingAudio: true})
                                            }} style={{marginRight: "30px"}}>Da
                                        </div>
                                        <div
                                            className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
                                            onClick={_ => this.setState({isUsingAudio: false})}>Nu
                                        </div>
                                    </div>
                                </div>
                                : <div className="Section">
                                    <div className="Title">Pentru a adauga un mesaj audio va rugam
                                        sa
                                        folositi un laptop/pc
                                    </div>
                                </div>}

                            {this.state.isUsingAudio &&
                                <AddAudio
                                    initialWeddingData={this.state.initialWeddingData}
                                    audioType={this.state.audioType}
                                    serverAudioUrl={this.state.serverAudioUrl}
                                    deleteServerAudio={this.state.deleteServerAudio}
                                    allowAudioRequest={this.state.allowAudioRequest}
                                    customAudioLink={this.state.customAudioLink}
                                    customAudioSecond={this.state.customAudioSecond}
                                    songSelected={this.state.songSelected}
                                    setAudioType={(value) => this.setState({audioType: value})}
                                    setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                                    setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                                    setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                                    setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                                    setSongSelected={(value) => this.setState({songSelected: value})}
                                />}
                        </> : ''}
                </div>

        }


        return (
            <div className="AddInvite-container">
                <HeaderWhite goBack={this.goBack}/>

                <LoadingModal loading={this.state.loading}/>

                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button',
                }} open={this.state.showModalChangeType} onClose={this.closeModalChangeType}>
                    <div style={{marginTop: "30px"}}>
                        <div>Daca schimbati tipul de invitatie, pierdeti datele introduse in cadrul
                            acesteia
                        </div>

                        <ConfirmButton text={"Confirm"} function={this.changeType}/>
                    </div>
                </Modal>

                <div className="AddInvite-contents" ref={this.scrollContainer}>
                    {
                        this.state.inviteId === -1 ?
                            <h4>Creează invitație</h4>
                            :
                            <h4>Editează invitație</h4>
                    }

                    <div
                        className={"input-zone " + (this.state.inviteType === '' || this.state.showChangeForm ? "large" : "")} style={{flex:1}}>
                        {/* {this.state.inviteType === "" && <div>
							<div className="Input_Type_Options">
								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Personal")}>Invitatie personala</div>

								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Video")}>Incarca link</div>

								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Custom")}>Invitatie personalizata</div>
							</div>

							<BackButton text={"Înapoi"} function={this.goBack} />
						</div>}

						{this.state.saveTheDateType !== "" && <div>
							<div onClick={this.openModalChangeType} className="ChangeChoiceButton">
								Alege alt tip de invitatie
							</div>
						</div>} */}

                        {/*
						<SelectInviteType inviteType={this.state.inviteType}
										  setInviteType={this.setInviteType}
										  useNewInvite={this.state.useNewInvite}
							goBack={this.goBack} openModalChangeType={this.openModalChangeType} text={"invitatie"} />
							*/}

                        {
                            this.state.inviteType === '' || this.state.showChangeForm ?
                                <>
                                    <h5 className={"invite-type-title"}>Alegeți un template
                                        predefinit</h5>
                                    <div className={"chooseTemplate-filters"}>
                                        <div
                                            className={"item " + (this.state.sortTemplates === 'top' ? 'active' : '')}
                                            onClick={() => this.setState({sortTemplates: 'top'})}>
                                            Top favorite
                                        </div>
                                        <div
                                            className={"item " + (this.state.sortTemplates === 'newest' ? 'active' : '')}
                                            onClick={() => this.setState({sortTemplates: 'newest'})}>
                                            Cele mai noi
                                        </div>
                                    </div>
                                    <div className={"chooseTemplate"}>
                                        {
                                            this.sortTemplates([...inviteTemplates]).reverse()
                                                .filter(item => ((item.type === this.state.initialWeddingData.eventType) ||
                                                    (item.otherTypes && item.otherTypes.includes(this.state.initialWeddingData.eventType))

                                                ) && item.id < 1000).map((template, i) => (
                                                <div key={i}
                                                     className={"item " + (template.title ? 'with-title' : '')}
                                                     onClick={() => {

                                                         if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                             this.changeTemplateValue('id', template.id)

                                                             if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteTemplate)) {
                                                                 this.setState({
                                                                     tutorialModalTemplate: true
                                                                 })
                                                             }
                                                             this.setState({
                                                                 inviteType: 'Custom',
                                                                 useNewInvite: true,
                                                                 showChangeModalToCustomNew: false,
                                                                 showChangeForm: false,
                                                                 templateSelected: true,
                                                                 activeTab: 'invitation'
                                                             }, () => {
                                                                 this.scrollContainerTop()
                                                                 this.changeTemplateValue('id', template.id)
                                                             })
                                                         } else {

                                                             if (this.state.useNewInvite === true && this.state.inviteType === 'Custom') {
                                                                 this.changeTemplateValue('id', template.id)
                                                                 this.setState({
                                                                     showChangeForm: false
                                                                 }, () => {
                                                                     this.scrollContainerTop()
                                                                     this.changeTemplateValue('id', template.id)
                                                                 })

                                                             } else {
                                                                 this.setState({
                                                                     showChangeModalToCustomNew: true,
                                                                     newTemplateId: template.id
                                                                 })
                                                             }
                                                         }

                                                     }}>

                                                    <img alt={""} src={template.preview}
                                                         loading={"lazy"}/>
                                                    {template.title ?
                                                        <div
                                                            className={"item-title"}>{template.title}</div>
                                                        : null}
                                                    {template.introAnimations ?
                                                        <div
                                                            className={"item-intro-anim-badge"}>Animație
                                                            disponibilă</div> : null}

                                                </div>
                                            ))
                                        }
                                    </div>

                                    {/* For old events using the old custom invite */}
                                    {this.state.useNewInvite === false && this.state.inviteType === 'Custom' ?
                                        <>
                                            <h5 className={"invite-type-title"}>Creează invitația de
                                                la 0</h5>
                                            <div className={"chooseTemplate"}>
                                                <div key={'custom'} className={"item"}
                                                     onClick={() => {

                                                         if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                             if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustom)) {
                                                                 this.setState({
                                                                     tutorialModalCustom: true
                                                                 })
                                                             }

                                                             this.setState({
                                                                 inviteType: 'Custom',
                                                                 useNewInvite: false,
                                                                 showChangeModalToCustom: false,
                                                                 showChangeForm: false,
                                                                 templateDetails: null,
                                                                 templateSelected: true,
                                                                 activeTab: 'invitation'
                                                             }, () => {
                                                                 this.scrollContainerTop()
                                                             })
                                                         } else {
                                                             if (this.state.useNewInvite === false && this.state.inviteType === 'Custom') {
                                                                 this.setState({
                                                                     showChangeForm: false
                                                                 }, () => {
                                                                     this.scrollContainerTop()
                                                                 })

                                                             } else {
                                                                 this.setState({
                                                                     showChangeModalToCustom: true
                                                                 })
                                                             }
                                                         }
                                                     }}>
                                                    <img alt={""}
                                                         src={'/images/custom-template.jpg'}/>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <h5 className={"invite-type-title"}>Creează invitația de
                                                la 0</h5>
                                            <div className={"chooseTemplate"}>
                                                <div key={'custom'} className={"item"}
                                                     onClick={() => {

                                                         if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                             if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustomTemplated)) {
                                                                 this.setState({
                                                                     tutorialModalCustomTemplated: true
                                                                 })
                                                             }
                                                             this.changeInviteTypeToCustomTemplated();
                                                             this.setState({
                                                                 inviteType: 'CustomTemplated',
                                                                 useNewInvite: false,
                                                                 showChangeModalToCustomTemplated: false,
                                                                 showChangeForm: false,
                                                                 templateSelected: true,
                                                                 activeTab: 'invitation'
                                                             }, () => {
                                                                 this.scrollContainerTop()
                                                                 this.changeInviteTypeToCustomTemplated();
                                                             })
                                                         } else {
                                                             if (this.state.inviteType === 'CustomTemplated') {
                                                                 this.setState({
                                                                     showChangeForm: false
                                                                 }, () => {
                                                                     this.scrollContainerTop()
                                                                 })

                                                             } else {
                                                                 this.setState({
                                                                     showChangeModalToCustomTemplated: true
                                                                 })
                                                             }
                                                         }
                                                     }}>
                                                    <img alt={""}
                                                         src={'/images/custom-template.jpg'}/>
                                                </div>
                                            </div>
                                        </>}

                                    <h5 className={"invite-type-title"}>Încarcă invitație deja
                                        creată</h5>
                                    <div className={"chooseTemplate"}>
                                        <div key={'personal'} className={"item"} onClick={() => {

                                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.invitePhoto)) {
                                                    this.setState({
                                                        tutorialModalPhoto: true
                                                    })
                                                }

                                                this.setState({
                                                    inviteType: 'Personal',
                                                    useNewInvite: false,
                                                    showChangeModalToPersonal: false,
                                                    showChangeForm: false,
                                                    templateDetails: null,
                                                    templateSelected: true,
                                                    activeTab: 'invitation'
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })
                                            } else {

                                                if (this.state.inviteType === 'Personal') {
                                                    this.setState({
                                                        showChangeForm: false
                                                    }, () => {
                                                        this.scrollContainerTop()
                                                    })

                                                } else {
                                                    this.setState({
                                                        showChangeModalToPersonal: true
                                                    })
                                                }
                                            }
                                        }}>
                                            <img alt={""} src={'/images/foto-template.jpg'}/>
                                        </div>

                                        <div key={'video'} className={"item"} onClick={() => {
                                            if (this.state.inviteId === -1 && this.state.templateSelected === false) {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteVideo)) {
                                                    this.setState({
                                                        tutorialModalVideo: true
                                                    })
                                                }
                                                this.setState({
                                                    inviteType: 'Video',
                                                    useNewInvite: false,
                                                    showChangeModalToVideo: false,
                                                    showChangeForm: false,
                                                    templateDetails: null,
                                                    templateSelected: true
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })
                                            } else {
                                                if (this.state.inviteType === 'Video') {
                                                    this.setState({
                                                        showChangeForm: false
                                                    }, () => {
                                                        this.scrollContainerTop()
                                                    })

                                                } else {
                                                    this.setState({
                                                        showChangeModalToVideo: true
                                                    })
                                                }
                                            }
                                        }}>
                                            <img alt={""} src={'/images/video-template.jpg'}/>
                                        </div>
                                    </div>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToCustom} onClose={() => {
                                        this.setState({
                                            showChangeModalToCustom: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustom)) {
                                                    this.setState({
                                                        tutorialModalCustom: true
                                                    })
                                                }
                                                this.setState({
                                                    inviteType: 'Custom',
                                                    useNewInvite: false,
                                                    showChangeModalToCustom: false,
                                                    showChangeForm: false,
                                                    templateDetails: null,
                                                    activeTab: 'invitation'
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToCustomTemplated}
                                           onClose={() => {
                                               this.setState({
                                                   showChangeModalToCustomTemplated: false
                                               })
                                           }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteCustomTemplated)) {
                                                    this.setState({
                                                        showChangeModalToCustomTemplated: true
                                                    })
                                                }
                                                this.changeInviteTypeToCustomTemplated();
                                                this.setState({
                                                    inviteType: 'CustomTemplated',
                                                    useNewInvite: false,
                                                    showChangeModalToCustomTemplated: false,
                                                    showChangeForm: false,
                                                    activeTab: 'invitation'
                                                }, () => {
                                                    this.scrollContainerTop()
                                                    this.changeInviteTypeToCustomTemplated();
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToCustomNew} onClose={() => {
                                        this.setState({
                                            showChangeModalToCustomNew: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteTemplate)) {
                                                    this.setState({
                                                        tutorialModalTemplate: true
                                                    })
                                                }
                                                this.changeTemplateValue('id', this.state.newTemplateId)
                                                this.setState({
                                                    inviteType: 'Custom',
                                                    useNewInvite: true,
                                                    showChangeModalToCustomNew: false,
                                                    showChangeForm: false,
                                                    activeTab: 'invitation'
                                                }, () => {
                                                    this.scrollContainerTop()
                                                    this.changeTemplateValue('id', this.state.newTemplateId)
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToPersonal} onClose={() => {
                                        this.setState({
                                            showChangeModalToPersonal: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.invitePhoto)) {
                                                    this.setState({
                                                        tutorialModalPhoto: true
                                                    })
                                                }
                                                this.setState({
                                                    inviteType: 'Personal',
                                                    useNewInvite: false,
                                                    showChangeModalToPersonal: false,
                                                    showChangeForm: false,
                                                    templateDetails: null,
                                                    activeTab: 'invitation'
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })

                                            }}/>
                                        </div>
                                    </Modal>

                                    <Modal classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal',
                                        closeButton: 'modal-close-button',
                                    }} open={this.state.showChangeModalToVideo} onClose={() => {
                                        this.setState({
                                            showChangeModalToVideo: false
                                        })
                                    }}>
                                        <div style={{marginTop: "30px"}}>
                                            <div>Atenție! Schimbând tipul de invitație veți pierde
                                                toate configurările
                                            </div>

                                            <ConfirmButton text={"Confirm"} function={() => {
                                                if (!this.state.initialWeddingData.tutorials || (this.state.initialWeddingData.tutorials && !this.state.initialWeddingData.tutorials.inviteVideo)) {
                                                    this.setState({
                                                        tutorialModalVideo: true
                                                    })
                                                }
                                                this.setState({
                                                    inviteType: 'Video',
                                                    useNewInvite: false,
                                                    showChangeModalToVideo: false,
                                                    showChangeForm: false,
                                                    templateDetails: null,
                                                    activeTab: 'invitation'
                                                }, () => {
                                                    this.scrollContainerTop()
                                                })

                                            }}/>
                                        </div>
                                    </Modal>
                                </>
                                :
                                <>

                                    <div onClick={() => this.setState({
                                        showChangeForm: true
                                    })} className="changeTemplateButton">
                                        Alege un alt template
                                    </div>

                                    {((this.state.inviteType === "Custom" && this.state.useNewInvite) || this.state.inviteType === 'CustomTemplated') &&
                                    (this.state.inviteId !== -1 || (this.props.match.params.new && this.props.match.params.new === 'new')) &&
                                    this.state.inviteId !== this.state.current_event.invite.id ?
                                        <div className={"language-selector"}
                                             onClick={() => this.setState({showLanguageModal: true})}>
                                            Schimbă limba
                                            invitației: {getLanguageName(this.state.language)} {getLanguageFlag(this.state.language)}
                                        </div>
                                        : null}

                                    {body}

                                    {this.state.activeTab === 'invitation' ?
                                        <>
                                            <div className="Section">
                                                <div className="Title">Îndrumăm participanții să se
                                                    înregistreze după ce
                                                    răspund la invitație pentru a fi la curent cu
                                                    toate detaliile pe
                                                    care doriți să le împărtășiți cu ei?
                                                </div>

                                                <div className="Options-Row">
                                                    <div
                                                        className={this.state.afterInviteResponseModalMode ? "active-option" : "inactive-option"}
                                                        onClick={_ => this.setState({afterInviteResponseModalMode: 1})}
                                                        style={{marginRight: "30px"}}>Da
                                                    </div>
                                                    <div
                                                        className={!this.state.afterInviteResponseModalMode ? "active-option" : "inactive-option"}
                                                        onClick={_ => this.setState({afterInviteResponseModalMode: 0})}>Nu
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.inviteType !== "" && <div>
                                                <div className="Section">
                                                    <div className="Title">Doriți să primiți
                                                        notificări cănd primiți
                                                        răspuns?
                                                    </div>

                                                    <div className="Options-Row">
                                                        <div
                                                            className={this.state.isUsingNotifications ? "active-option" : "inactive-option"}
                                                            onClick={_ => this.setState({isUsingNotifications: true})}
                                                            style={{marginRight: "30px"}}>Da
                                                        </div>
                                                        <div
                                                            className={!this.state.isUsingNotifications ? "active-option" : "inactive-option"}
                                                            onClick={_ => this.setState({isUsingNotifications: false})}>Nu
                                                        </div>
                                                    </div>
                                                </div>

                                                <CustomQuestions eventId={this.state.eventId}
                                                                 questions={this.state.questions}
                                                                 shouldUseQuestions={this.state.isUsingCustomQuestions}
                                                                 canModifyQuestions={this.state.canModifyQuestions}
                                                                 eventType={this.state.eventType}
                                                                 changeUseQuestionsState={this.changeUseQuestionsState}
                                                                 changeQuestions={this.changeQuestions}
                                                                 event={this.state.current_event}
                                                                 language={this.state.language}
                                                                 intl={this.props.intl}
                                                                 hideDontKnowButton={this.state.hideDontKnowButton}
                                                                 setHideDontKnowButton={(val) => this.setState({hideDontKnowButton: val})}
                                                                 hideChildrenQuestion={this.state.hideChildrenQuestion}
                                                                 setHideChildrenQuestion={(val) => this.setState({hideChildrenQuestion: val})}
                                                                 hideConfirmForm={this.state.hideConfirmForm}
                                                                 setHideConfirmForm={(val) => this.setState({hideConfirmForm: val})}
                                                                 hideEmailInput={this.state.hideEmailInput}
                                                                 setHideEmailInput={(val) => this.setState({hideEmailInput: val})}
                                                />

                                                {this.state.inviteId !== -1 ?
                                                    <>
                                                        <div className="Section">
                                                            <div className="Title">Doriți să
                                                                descărcați invitația ca
                                                                imagine?
                                                            </div>

                                                            <div className="Options-Row">
                                                                <div
                                                                    className={"inactive-option"}
                                                                    onClick={_ => this.setState({showDownloadImageModal: true})}
                                                                    style={{
                                                                        marginRight: "30px",
                                                                        cursor: "pointer"
                                                                    }}>Da
                                                                </div>
                                                                <div
                                                                    className={"active-option"}
                                                                    onClick={_ => this.setState({showDownloadImageModal: false})}
                                                                    style={{cursor: "pointer"}}>Nu
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null}

                                                {/*
                                        <Button
                                            onClick={() => {
                                                if (this.state.inviteType === 'Custom' && this.state.useNewInvite) {
                                                    this.setState({
                                                        tutorialModalTemplate: true
                                                    })
                                                }

                                                if (this.state.inviteType === 'Custom' && !this.state.useNewInvite) {
                                                    this.setState({
                                                        tutorialModalCustom: true
                                                    })
                                                }

                                                if (this.state.inviteType === 'Personal') {
                                                    this.setState({
                                                        tutorialModalPhoto: true
                                                    })
                                                }

                                                if (this.state.inviteType === 'Video') {
                                                    this.setState({
                                                        tutorialModalVideo: true
                                                    })
                                                }

                                            }}
                                            color="green"
                                            style={{
                                                backgroundColor: "#05AFF2",
                                                borderRadius: "20px",
                                                width: "100%",
                                                marginTop: "10px",
                                                marginBottom: "10px"
                                            }}>Informații utile</Button> */}

                                            </div>}
                                        </> : null}

                                    {this.state.activeTab === 'introAnimation' ?
                                        <IntroAnimationConfigComp
                                            templateConfigValues={this.state.templateConfigValues}
                                            isUsingAudio={this.state.isUsingAudio}
                                            initialWeddingData={this.state.initialWeddingData}
                                            templateDetails={this.state.templateDetails}
                                            setTemplateDetails={(value) => this.setState({templateDetails: value})}
                                            audioType={this.state.audioType}
                                            serverAudioUrl={this.state.serverAudioUrl}
                                            deleteServerAudio={this.state.deleteServerAudio}
                                            allowAudioRequest={this.state.allowAudioRequest}
                                            customAudioLink={this.state.customAudioLink}
                                            customAudioSecond={this.state.customAudioSecond}
                                            songSelected={this.state.songSelected}
                                            setAudioType={(value) => this.setState({audioType: value})}
                                            setAudioBlobData={(value) => this.setState({audioBlobData: value})}
                                            setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
                                            setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
                                            setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
                                            setSongSelected={(value) => this.setState({songSelected: value})}
                                            setIsUsingAudio={(value) => this.setState({isUsingAudio: value})}
                                        /> : null}
                                    {this.state.activeTab === 'introScreen' ?
                                        <IntroScreenEditComp
                                            eventType={this.state.eventType}
                                            weddingData={this.state.initialWeddingData}
                                            saveWeddingData={this.saveWeddingData}
                                            templateDetails={this.state.templateDetails}
                                            setTemplateDetails={(val) => this.setState({templateDetails: val})}
                                            audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                            image1Link={this.state.image1Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image1Url}` : ''}
                                            image2Link={this.state.image2Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image2Url}` : ''}
                                            image3Link={this.state.image3Url ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.image3Url}` : ''}
                                            coverImageLink={this.state.coverImageUrl ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.coverImageUrl}` : ''}
                                            newImage1={this.state.newImage1}
                                            newImage2={this.state.newImage2}
                                            newImage3={this.state.newImage3}
                                            newCoverImage={this.state.newCoverImage}
                                            setNewImage1={(val) => {
                                                this.setState({newImage1: val})
                                            }}
                                            setNewImage2={(val) => {
                                                this.setState({newImage2: val})
                                            }}
                                            setNewImage3={(val) => {
                                                this.setState({newImage3: val})
                                            }}
                                            setNewCoverImage={(val) => {
                                                this.setState({newCoverImage: val})
                                            }}
                                            changeTypeToCustom={() => {
                                                this.setState({
                                                    activeTab: 'invitation',
                                                    templateDetails: null,
                                                    useNewInvite: false
                                                })
                                            }}
                                            updateLocations={(val) => this.setState({locations: val})}
                                        /> : null}


                                    {this.state.inviteId !== -1 ?
                                        <div className={"Section"}>
                                            <Button
                                                onClick={() => this.createInvite(true)}
                                                color="green"
                                                style={{
                                                    backgroundColor: "#F26157",
                                                    borderRadius: "20px",
                                                    width: "100%",
                                                    marginTop: "10px",
                                                    marginBottom: "10px"
                                                }}>Previzualizează</Button>

                                            <Button
                                                onClick={() => {
                                                    this.setInviteSentDate()
                                                    this.createInvite(false, true)
                                                }}
                                                color="green"
                                                style={{
                                                    backgroundColor: "green",
                                                    borderRadius: "20px",
                                                    width: "100%",
                                                    marginTop: "10px",
                                                    marginBottom: "20px"
                                                }}>Trimite invitația</Button>
                                            <Button
                                                onClick={() => this.createInvite(false, false, true)}
                                                color="green"
                                                style={{
                                                    backgroundColor: "#05AFF2",
                                                    borderRadius: "20px",
                                                    width: "100%",
                                                    marginTop: "0",
                                                    marginBottom: "20px"
                                                }}>Cumpără pachet</Button>
                                        </div>
                                        : null
                                    }

                                    <BackAndConfirmButtons loading={this.state.savingData}
                                                           textBack={"Înapoi"}

                                                           textConfirm={"Salvează"}
                                                           functionBack={this.goBack}
                                                           functionConfirm={() => this.createInvite(false)}/>

                                    <div style={{paddingTop: '20px'}}></div>
                                </>
                        }


                    </div>
                </div>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalPhoto}
                    focusTrapped={false}
                    onClose={() => this.updateTutorial('invitePhoto')}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>Dacă ai un model de invitație deja creat, îl poti incarca in platforma
                            Ality și distribui
                            celor pe care ii vrei alături de tine la petrecere. </p>

                        <Button
                            onClick={() => this.updateTutorial('invitePhoto')}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalVideo}
                    focusTrapped={false}
                    onClose={() => this.updateTutorial('inviteVideo')}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>Dacă ai un model de invitație video deja creat, atunci tot ce trebuie să
                            faci este sa îl urci
                            pe youtube si apoi il poti incarca in platforma Ality prin simpla
                            adaugare a link-ului de pe
                            youtube și îl distribui tuturor celor pe care ii vrei alături de tine la
                            petrecere. </p>

                        <Button
                            onClick={() => this.updateTutorial('inviteVideo')}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalCustom}
                    focusTrapped={false}
                    onClose={() => this.updateTutorial('inviteCustom')}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>În Ality poți sa iti creezi propria invitatie personalizata pe care sa o
                            expediezi virtual
                            participanților: încarcă fotografia de fundal, alege culoarea,
                            dimensiunea si fontul
                            textului, inregistreaza un mesaj audio sau adauga o melodie.</p>

                        <Button
                            onClick={() => this.updateTutorial('inviteCustom')}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalCustomTemplated}
                    focusTrapped={false}
                    onClose={() => this.updateTutorial('inviteCustomTemplated')}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>În Ality poți sa iti creezi propria invitatie personalizata pe care sa o
                            expediezi virtual
                            participanților: încarcă fotografia de fundal, alege culoarea,
                            dimensiunea si fontul
                            textului, inregistreaza un mesaj audio sau adauga o melodie.</p>

                        <Button
                            onClick={() => this.updateTutorial('inviteCustomTemplated')}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalTemplate}
                    focusTrapped={false}
                    onClose={() => this.updateTutorial('inviteTemplate')}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>Creează invitația adaugând pozele
                            preferate, înregistrări audio sau întrebări personalizate. Te-am făcut
                            deja
                            curios?</p>

                        <Button
                            onClick={() => this.updateTutorial('inviteTemplate')}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalPreview}
                    focusTrapped={false}
                    onClose={() => {
                        this.updateTutorial('invitePreview')
                        this.props.history.push('/organizer/invitationPreview/' + this.props.match.params.id)
                    }}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>Poți previzualiza invitația pentru a fi sigur că toate datele sunt
                            corecte înainte de a o
                            trimite invitațiilor.
                            Secțiunea de întrebări personalizate poate fi vazută apăsând pe butonul
                            "Particip" dinn
                            partea de jos a invitației.</p>
                        <Button
                            onClick={() => {
                                this.updateTutorial('invitePreview')
                                this.props.history.push('/organizer/invitationPreview/' + this.props.match.params.id)
                            }}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalSendInviteNotice}
                    focusTrapped={false}
                    onClose={() => {
                        this.updateTutorial('inviteSendNotice')
                        this.setState({
                            tutorialModalSendInviteNotice: false
                        })
                        this.chooseModal()
                    }}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>ATENȚIE! Nu aveți nicio locație adaugata pe invitație, iar invitații nu
                            vor ști unde și la ce
                            ora trebuie sa fie prezenți.
                            Locațiile se adauga în secțiunea a 3-a (ultima) a invitației.
                        </p>

                        <Button
                            onClick={() => {
                                this.updateTutorial('inviteSendNotice')
                                this.setState({
                                    tutorialModalSendInviteNotice: false
                                })
                                this.chooseModal()
                            }}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>
                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModalSendInvite}
                    focusTrapped={false}
                    onClose={() => {
                        this.updateTutorial('inviteSend')
                        this.openSendModal()
                    }}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>Va rugam sa aveți în vedere ca după primirea primului răspuns la
                            invitație numai puteți aduce
                            modificări la întrebările personalizate așa că va rugam sa acordati
                            multă atenție
                            întrebărilor personalizate înainte de trimiterea invitațiilor. Pentru a
                            putea aduce
                            modificări la întrebările personalizate va trebui sa stergeti toate
                            răspunsurile primite și
                            apoi o sa puteți modifica întrebările. </p>

                        <Button
                            onClick={() => {
                                this.updateTutorial('inviteSend')
                                this.openSendModal()
                            }}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Ok</Button>
                    </div>
                </Modal>


                <SendInviteModal
                    showModal={this.state.showSendModal}
                    setShowModal={(value) => this.setState({showSendModal: value})}
                    event={this.state.current_event}
                />

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showLanguageModal}
                    focusTrapped={false}
                    onClose={() => this.setState({showLanguageModal: false})}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Schimbare limbă</h3>
                        <br/>

                        <p>Alegeți limba dorită</p>
                        <div className={"invite-lang-change-modal"}>
                            {
                                getAvailableInviteLanguages().map((lang, i) => (
                                    <div key={i}
                                         className={"lang-item " + (this.state.selectedLanguage === lang ? 'active' : '')}
                                         onClick={() => this.setState({selectedLanguage: lang})}>
                                        {getLanguageName(lang)} {getLanguageFlag(lang)}
                                    </div>
                                ))
                            }
                        </div>

                        <p style={{color: 'red'}}><strong>Atenție!</strong><br/> Schimbarea limbii
                            invitației va reseta
                            toate textele salvate pe aceasta!</p>

                        <Button
                            onClick={() => this.changeInviteLanguage()}
                            color="green"
                            disabled={this.state.language === this.state.selectedLanguage}
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Confirmă schimbarea</Button>
                    </div>
                </Modal>


                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showCannotEditModal}
                    focusTrapped={false}
                    onClose={() => {
                    }}
                    showCloseIcon={false}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Atenție!</h3>
                        <br/>

                        <p>Invitația nu mai poate fi modificată deoarece au trecut mai mult de 48 de
                            ore de la data
                            evenimentului!</p>
                        <p>În cazul în care aveți neapărat nevoie să modificați invitația vă rugăm
                            să ne contactați la
                            adresa contact@ality.ro.</p>

                        <Button
                            onClick={() => this.goBack()}
                            color="green"
                            style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "20px"
                            }}>Am înțeles</Button>
                    </div>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showDownloadImageModal}
                    focusTrapped={false}
                    onClose={() => {
                        this.setState({showDownloadImageModal: false})
                    }}
                >
                    <div style={{marginTop: "20px"}}>

                        <h3 style={{color: "red", marginBottom: "10px"}}>Atenție!</h3>

                        <p>În cazul în care doriți să descărcați și să trimiteți invitația sub forma
                            de fișier PNG
                            (poză), dorim să vă informăm că <span
                                style={{fontWeight: "600", color: "red"}}>NU</span> veți mai putea
                            beneficia de
                            funcționalitățile oferite de platforma Ality, care includ:</p>

                        <p style={{fontWeight: "600", color: "red"}}>
                            - Centralizarea răspunsurilor în aplicație<br/>
                            - Integrarea locațiilor prin Waze/Google Maps<br/>
                            - Numărătoarea inversă a timpului până la eveniment<br/>
                        </p>

                        <p>
                            Cu toate acestea, aveți opțiunea de a adăuga manual răspunsurile
                            invitaților în
                            platformă.<br/>
                            În cazul în care doriți totuși să trimiteți invitația sub formă de poză,
                            vă recomandăm să
                            trimiteți împreună cu aceasta și link-ul invitației digitale, unde
                            invitații pot totuși să
                            răspundă, iar răspunsul lor va fi centralizat, iar voi veți putea
                            beneficia de
                            funcționalitățile enumerate mai sus.
                        </p>

                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(this.state.sendInviteMessage + ' https://ality.ro/inv/' + this.state.initialWeddingData.id_eveniment + '/' + (this.props.match.params.new && this.props.match.params.new !== 'new' ? this.props.match.params.new : '0'))
                                toast.success('Link-ul a fost copiat!')
                            }}
                            color="green"
                            style={{
                                backgroundColor: '#05AFF2',
                                color: '#ffffff',
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "10px",
                                marginBottom: "10px"
                            }}>Copiază link-ul</Button>

                        <p style={{marginTop: '5px'}}>Alegeți formatul invitației</p>
                        <div className="invite-download-size-selector">
                            <div
                                className={"option " + (this.state.inviteDownloadSize === 1 ? 'active' : '')}
                                onClick={() => this.setState({inviteDownloadSize: 1})}
                            >Mică
                            </div>
                            <div
                                className={"option " + (this.state.inviteDownloadSize === 2 ? 'active' : '')}
                                onClick={() => this.setState({inviteDownloadSize: 2})}
                            >Normală
                            </div>
                            <div
                                className={"option " + (this.state.inviteDownloadSize === 3 ? 'active' : '')}
                                onClick={() => this.setState({inviteDownloadSize: 3})}
                            >Mare
                            </div>
                        </div>


                        <Button
                            onClick={() => {

                                if (this.state.downloadInviteLoading) {
                                    return
                                }

                                if ((getWeddingParticipantsPackageNumber(this.state.initialWeddingData) === 0 || getWeddingParticipantsPackageNumber(this.state.initialWeddingData) === 5)) {
                                    toast.warn('Această funcționalitate este disponibilă doar pentru pachetul Premium sau pachetul Invitație digitală cu răspunsuri nelimitate')
                                    return
                                }

                                /*
                                if(window.alityAppView) {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                        action: 'openLink',
                                        url: 'https://utils.ality.ro/api/download-image/' + this.state.initialWeddingData.id_eveniment + '/' + (this.props.match.params.new && this.props.match.params.new !== 'new' ? this.props.match.params.new : '0'),
                                    }))
                                } else {
                                    window.open('https://utils.ality.ro/api/download-image/' + this.state.initialWeddingData.id_eveniment + '/' + (this.props.match.params.new && this.props.match.params.new !== 'new' ? this.props.match.params.new : '0'))
                                }*/

                                let url = 'https://utils.ality.ro/api/download-image/' + this.state.initialWeddingData.id_eveniment + '/' + (this.props.match.params.new && this.props.match.params.new !== 'new' ? this.props.match.params.new : '0') + '?size=' + this.state.inviteDownloadSize

                                this.setState({
                                    downloadInviteLoading: true
                                })

                                if (window.alityAppView) {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                        action: 'openLink',
                                        url: 'https://utils.ality.ro/api/download-image/' + this.state.initialWeddingData.id_eveniment + '/' + (this.props.match.params.new && this.props.match.params.new !== 'new' ? this.props.match.params.new : '0') + '?size=' + this.state.inviteDownloadSize,
                                    }))
                                    this.setState({
                                        downloadInviteLoading: false
                                    })
                                } else {
                                    axios.get(url, {
                                        responseType: 'blob',
                                    })
                                        .then((res) => {

                                            // Create blob link to download
                                            const url = window.URL.createObjectURL(
                                                new Blob([res.data]),
                                            );
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute(
                                                'download',
                                                `invitatie.png`,
                                            );

                                            // Append to html link element page
                                            document.body.appendChild(link);

                                            // Start download
                                            link.click();

                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);

                                            this.setState({
                                                downloadInviteLoading: false
                                            })

                                        })
                                }


                            }}
                            color="green"
                            style={{
                                border: "1px solid green",
                                backgroundColor: 'green',
                                color: '#ffffff',
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: "10px",
                                marginBottom: "10px"
                            }}>{this.state.downloadInviteLoading ? 'Se pregătește ...' : 'Descarcă invitația'}</Button>

                    </div>
                </Modal>

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default injectIntl(EditInvite);
